// Imports
import Portfolio from 'views/portfolio';


const routes = [

	{
		name: 'Developing Portfolio',
		path: '',
		component: Portfolio
	},
];

export default routes;
